<template>
  <div class="content">
    <p>
      Mit über einem Jahrzehnt Erfahrung in der IT-Branche stehe ich Ihnen als
      erfahrener Softwareentwickler und IT-Consultant zur Verfügung. Meine
      Expertise umfasst ein breites Spektrum an Technologien und
      Programmiersprachen, darunter C#, WPF, WinForms, Angular, VueJS, CSS,
      HTML, (T)SQL/MS SQL, WordPress, Jenkins, Entity Framework... Diese
      umfassende Praxiserfahrung ermöglicht es mir, individuell auf Ihre
      Anforderungen einzugehen und maßgeschneiderte Lösungen zu entwickeln.
    </p>

    <p>
      Neben meinen aktiven Fähigkeiten habe ich im Laufe der Jahre auch passive
      Kenntnisse in weiteren Bereichen wie ASP.NET Razor/Blazor, NodeJS,
      WooCommerce, ABAP, SAP, Azure, MySQL, AWS, Docker und vielen anderen
      erworben. Diese Vielfalt an Technologien ermöglicht es mir, einen
      ganzheitlichen Ansatz für Ihre IT-Projekte zu bieten.
    </p>

    <p>
      In meiner Laufbahn habe ich nicht nur technische Expertise gesammelt,
      sondern auch wertvolle Erfahrungen in der operativen Ebene des
      Requirements Engineerings, Projektmanagements und in Scrum-Umgebungen.
      Diese Kompetenzen stellen sicher, dass Ihre Projekte nicht nur technisch
      erstklassig, sondern auch effizient und zielgerichtet umgesetzt werden.
    </p>

    <p>
      Meine Qualifikationen werden durch verschiedene Zertifikate, wie das MCITP
      (Microsoft Certified IT Professional) und AWS Certified Cloud
      Practitioner, unterstrichen. Diese Zertifizierungen belegen mein
      Engagement und meine kontinuierliche Weiterbildung in der schnelllebigen
      IT-Welt.
    </p>

    <p>
      Ich biete flexible Zusammenarbeitsmodelle an: Ob Sie mich auf Stundenbasis
      für klassisches Outsourcing benötigen oder ein ganzes Projekt durch meine
      Freelancer-Community umsetzen lassen möchten – ich stehe bereit, um Ihre
      individuellen Bedürfnisse zu erfüllen.
    </p>

    <button @click="GoToCommunityPage()" class="contact-button">
      Freelancer Community
    </button>
    <p></p>
    <p>
      Meine Spezialisierung liegt in der Entwicklung maßgeschneiderter Software
      und Web-Anwendungen, die präzise auf die Anforderungen Ihres Unternehmens
      zugeschnitten sind. Für eine Zusammenarbeit, die Innovation, Erfahrung und
      maßgeschneiderte Lösungen vereint
    </p>
    <button @click="GoToContactPage()" class="contact-button">
      Kontaktieren Sie mich
    </button>
  </div>
</template>

<script>
export default {
  name: "OutsourcingPage",
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
    GoToCommunityPage() {
      this.$router.push("/community");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto; /* Zentriert den Inhalt und behält den Abstand oben/unten */
  max-width: 800px; /* Begrenzt die Breite für große Bildschirme */
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .content {
    margin: 3% 2%; /* Reduces side margins on smaller screens */
    max-width: 95%; /* Allows content to take more width on smaller screens */
  }

  .content h1,
  .content h2 {
    font-size: 1.8em; /* Adjusts heading font sizes for smaller screens */
  }

  .content p,
  .external-link {
    font-size: 1em; /* Adjusts paragraph and link font sizes for smaller screens */
  }

  button {
    padding: 12px 24px; /* Larger buttons for easier interaction on touch devices */
    width: 100%; /* Full width buttons on small screens */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small devices */
  .content h1,
  .content h2 {
    font-size: 1.5em;
  }

  .content p,
  .external-link {
    font-size: 0.9em;
  }
}
</style>
