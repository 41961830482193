<template>
  <div class="content">
    <p>
      <b>Kontaktdaten:</b>
    </p>
    <p>Vedran Ilic BSc</p>
    <p>Telefon: +43 676 3183835</p>
    <p>Email: info@vedil.at</p>
    <p>
      <a
        href="https://www.linkedin.com/in/vedran-ilic-b5a794159"
        target="_blank"
        class="external-link"
      >
        <img src="../../assets/linkedIn.png" alt="LinkedIn" />
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
    GoToCommunityPage() {
      this.$router.push("/community");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto; /* Zentriert den Inhalt und behält den Abstand oben/unten */
  max-width: 800px; /* Begrenzt die Breite für große Bildschirme */
  padding: 0 1em; /* Add side padding for smaller screens */
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
  font-size: 20px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .content {
    margin: 3% 2%;
    max-width: 95%;
  }

  .content p {
    font-size: 1em; /* Adjusted font size for medium screens */
  }

  .external-link img {
    width: 40px; /* Slightly smaller icon for medium screens */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small devices */
  .content {
    margin: 3% 5%;
  }

  .content p {
    font-size: 0.9em; /* Smaller font size for small screens */
  }

  .external-link img {
    width: 30px; /* Smaller icon for small screens */
  }
}
</style>
