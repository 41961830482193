<template>
  <div class="content">
    <h2>Impressum</h2>
    <p>
      <b
        >Informationen und Offenlegung gemäß &sect; 25 MedienG, &sect;5 (1) ECG
        &sect; 63 GewO und &sect; 14 UGB</b
      >
    </p>
    <p><b>Dienstanbieter und Medieninhaber:</b> Vedran Ilic</p>
    <p><b>Anschrift:</b> Austeingasse 26, Tür 4, 8020 Graz, Österreich</p>
    <p>
      <b>Umsatzsteueridentifikationsnummer:</b> ATU74349868<br />
      <b>Behörde gem. ECG:</b> Magistrat der Stadt Graz<br />
      <b>Berufszweig(e):</b> IT-Dienstleistung<br />
      <b>Gewerbewortlaut:</b> Dienstleistungen in der automatischen
      Datenverarbeitung und Informationstechnik<br />
      <b>Mitgliedschaften:</b>
      Wirtschaftskammer Steiermark<br />
    </p>
    <p>
      <b>Kontaktdaten:</b> <br />
      Telefon: +43 676 3183835 <br />
      Email: info@vedil.at <br />
    </p>

    <p><b>Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at <br /></p>
    <p>
      <b>Online Streitbeilegung:</b> Verbraucher mit einer Niederlassung in
      Österreich oder einem sonstigen Vertragsstaat der ODR-VO, haben die
      Möglichkeit bei Problemen hinsichtlich des entgeltlichen Kaufs von Waren
      oder Dienstleistungen die Online-Streitbelegung der Europäischen
      Kommission über folgende Plattform in Anspruch zu nehmen
      https://ec.europa.eu/consumers/odr
    </p>
  </div>
</template>

<script>
export default {
  name: "ImpressumPage",
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto; /* Zentriert den Inhalt und behält den Abstand oben/unten */
  max-width: 800px; /* Begrenzt die Breite für große Bildschirme */
  padding: 0 1em; /* Adds padding on smaller screens */
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .content {
    margin: 3% 2%;
    max-width: 95%;
  }

  .content h2 {
    font-size: 1.8em; /* Adjusted font size for smaller screens */
  }

  .content p {
    font-size: 1em; /* Adjusted font size for paragraphs */
  }
}

@media (max-width: 480px) {
  .content {
    margin: 3% 5%;
  }

  .content h2 {
    font-size: 1.5em; /* Further adjusted font size for very small screens */
  }

  .content p {
    font-size: 0.9em; /* Further adjusted font size for paragraphs */
  }
}
</style>
