<template>
  <div id="app">
    <transition name="fade">
      <div v-if="showCookieConsent" class="cookie-consent">
        Diese Website benutzt Cookies. Wenn Sie die Website weiter nutzen, gehen
        wir von Ihrem Einverständnis aus.
        <button @click="acceptCookies">OK</button>
        <button @click="DataProtection()">Datenschutzerklärung</button>
      </div>
    </transition>

    <header>
      <AppHeader></AppHeader>
    </header>
    <body>
      <img class="home-img" src="./assets/web-solution-big.jpg" alt="" />
      <router-view></router-view>
    </body>
    <footer>
      <p>© 2023 - {{ currentYear }} VEDIL. Alle Rechte vorbehalten.</p>
      <p>
        <router-link class="nav-link" to="/impressum">Impressum </router-link>
        <router-link class="nav-link" to="/datenschutz">
          Datenschutz</router-link
        >
      </p>
    </footer>
  </div>
</template>

<script>
import AppHeader from "./components/Header.vue";

export default {
  name: "App",
  components: {
    AppHeader,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showCookieConsent: !localStorage.getItem("cookieConsent"),
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("cookieConsent", "true");
      this.showCookieConsent = false;
    },
    DataProtection() {
      this.$router.push("/datenschutz");
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif, "Arial";
  color: rgb(92, 85, 85);
  /* background: #87CEFA; */
  padding-top: 60px; /* Adjust as per your header height */
  padding-bottom: 40px; /* Adjust as per your footer height */
}

header {
  background-color: #f4f4f4;
  text-align: center;
  position: fixed; /* Fix the header to the top of the viewport */
  top: 0; /* Align the top edge of the header with the top of the viewport */
  left: 0; /* Align the left edge of the header with the left of the viewport */
  width: 100%; /* Ensure the header stretches across the full width */
  z-index: 1000; /* Ensure the header stays on top of other content */
}

footer {
  background-color: #2f63f1; /* Himmelblau für den Footer */
  color: #ffffff; /* Weiß für den Text */
  text-align: center;
  padding: 10px 0;

  position: fixed; /* Fix the footer at the bottom of the viewport */
  bottom: 0; /* Align the bottom edge of the footer with the bottom of the viewport */
  left: 0; /* Align the left edge of the footer with the left of the viewport */
  width: 100%; /* Ensure the footer stretches across the full width */
  z-index: 1000; /* Ensure the footer stays on top of other content */
}

footer p {
  font-size: 12px;
}

.home-img {
  width: 100%;
  height: 400px; /* You can adjust this value as needed */
  object-fit: cover; /* This will cover the area without stretching the image */
}

.app-offer {
  margin: 50px 0;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 1001; /* Höher als der Footer und Header */
}

.cookie-consent button {
  color: #2f63f1;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
}

.cookie-consent button:hover {
  background-color: #f5f5f5;
}

.nav-link,
.nav-link:visited {
  /* Set the styles for visited state identical to the normal state */
  color: inherit; /* This will make the color the same as the unvisited links */
  text-decoration: none; /* Assuming you want no underline for visited links */
}

/* Define the entering and leaving transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Starting state for entering element */
.fade-enter-from {
  opacity: 0;
  transform: translateY(-20px); /* Adjust as needed */
}

/* Ending state for leaving element */
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px); /* Adjust as needed */
}

/* Ensure the element is fully visible when entering finishes */
.fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}

/* Starting state for leaving (not always necessary unless different from .fade-enter-to) */
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  header,
  footer {
    padding: 15px; /* Reduced padding */
  }

  .home-img {
    height: 300px; /* Adjusted height */
  }

  .cookie-consent button {
    padding: 8px 15px; /* Larger buttons for easier interaction */
  }
}

/* Mobile Specific Styles */
@media (max-width: 480px) {
  body {
    font-size: 12px;
  }

  header,
  footer {
    padding: 10px; /* Even smaller padding for very small devices */
  }

  .home-img {
    height: 200px; /* Further adjusted height */
  }

  .cookie-consent {
    font-size: 14px; /* Larger font for readability */
  }

  .cookie-consent button {
    font-size: 16px; /* Larger font for easier interaction */
  }

  footer p {
    font-size: 12px; /* Adjusted font size for footer */
  }
}
</style>
