<template>
  <div class="content">
    <p>
      Haben Sie Fragen bezüglich unserer Community, oder sind Sie selbst
      Freelancer und wollen Teil der Community sein?
    </p>
    <button @click="GoToContactPage()" class="contact-button">
      Kontaktieren Sie uns
    </button>
    <h2>Mitglieder:</h2>
    <ul>
      <li
        class="column-layout"
        v-for="freelancer in freelancerListSortedRandom"
        :key="freelancer.id"
      >
        <div class="column first-column">
          <img
            :src="freelancer.image"
            alt="Profile Image"
            class="profile-image"
          />
        </div>
        <div class="column second-column">
          <div class="row">
            <img src="../../assets/name.png" alt="Name" class="profile-icons" />
            {{ freelancer.name }}
          </div>
          <div class="row">
            <img
              src="../../assets/location.png"
              alt="Location"
              class="profile-icons"
            />
            {{ freelancer.location }}
            <span class="icon-container">
              <a v-if="freelancer.email" :href="'mailto:' + freelancer.email">
                <img
                  src="../../assets/email.png"
                  alt="E-Mail"
                  class="profile-icons" /></a
              ><a
                v-if="freelancer.website"
                :href="freelancer.website"
                target="_blank"
              >
                <img
                  src="../../assets/website.png"
                  alt="Website"
                  class="profile-icons contact-icon"
              /></a>
              <a
                v-if="freelancer.linkedin"
                :href="freelancer.linkedin"
                target="_blank"
              >
                <img
                  src="../../assets/linkedinsmall.png"
                  alt="LinkedIn"
                  class="profile-icons contact-icon"
              /></a>
            </span>
          </div>

          <div class="row">
            <img
              src="../../assets/tech.png"
              alt="Tech Stack"
              class="profile-icons"
            /><label>{{ freelancer.techstack1 }}</label
            ><br /><label>{{ freelancer.techstack2 }}</label>
          </div>
        </div>
        <div class="separator"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CommunityPage",
  data() {
    return {
      freelancerList: [
        {
          id: 1,
          image: require("@/assets/vedran.jpg"),
          name: "Vedran Ilic",
          location: "Graz",
          email: "vedran.ilic@vedil.at",
          website: "https://www.vedil.at",
          linkedin: "https://www.linkedin.com/in/vedran-ilic-b5a794159",
          techstack1:
            "Development | Requirements Engineering | Projektmanagement",
          techstack2:
            "C# (WPF WinForms ASP.NET) | Angular | VueJS | (T)SQL | WordPress | ...",
        },
        {
          id: 2,
          image: require("@/assets/siff.jpg"),
          name: "Stiff Roy",
          location: "Innsbruck",
          email: "stiff.roy@einzigtech.com",
          website: "https://www.einzigtech.com/de/",
          linkedin: "https://www.linkedin.com/in/stiff-roy/",
          techstack1: "Development | Blockchain | Digital Marketing",
          techstack2:
            "PHP (Symfony, Twig, Doctrine, Api-Platform, Sulu, Sonata, PimCore, Sylius) | VueJS | ReactJS | ...",
        },
        {
          id: 3,
          image: require("@/assets/dan.jpg"),
          name: "Dan Scurtu",
          location: "Leoben",
          email: "info@dssofttech.net",
          website: "https://dssofttech.net/",
          linkedin: "https://www.linkedin.com/in/dan-scurtu-a4b4922ba/",
          techstack1: "Development",
          techstack2:
            "React | C# (WPF WinForms ASP.NET) | VueJS | Javascript | (T)SQL | MSSQL | ...",
        },
        {
          id: 4,
          image:
            "https://www.freelancermap.at/profileimages/Laszlo-Schaffer-79045-xxl.jpg",
          name: "Laszlo Schäffer",
          location: "Graz",
          email: "laszloschaffer@gmail.com",
          website: "",
          linkedin:
            "https://www.freelancermap.at/profil/senior-software-entwickler-79045",
          techstack1: "Development (Web, Desktop, Mobile)",
          techstack2:
            "C# | Dart | Flutter | Angular | React | Go | Java| MySQL | Oracle | Docker | Linux...",
        },
      ],
    };
  },
  computed: {
    freelancerListSortedRandom() {
      let array = [...this.freelancerList];
      return array.sort(() => Math.random() - 0.5);
    },
  },
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
    GoToCommunityPage() {
      this.$router.push("/community");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto;
  max-width: 800px;
  padding: 0 1em;
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
  width: 100%; /* Full width on smaller screens */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

.column-layout {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start; /* Aligns items to the start, reducing the default space */
  align-items: flex-start;
  padding: 10px;
}

.column {
  padding: 5px;
  text-align: left;
  flex-grow: 0; /* Prevent columns from growing */
  flex-shrink: 0; /* Prevent columns from shrinking */
  flex-basis: auto; /* Base width on content size */
}

.row {
  margin-bottom: 10px; /* Adds space between the rows */
  /* Add any other desired styling */
}

.second-column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  font-weight: bold;
}

.first-column {
  display: inline-block;
  padding: 5px;
  text-align: left;
  margin-right: 20px; /* Adjust this value to control the space */
}

.profile-image {
  width: 150px; /* Ensure the image takes up the full width of its container */
  height: 150px; /* Adjust this as needed */
  object-fit: cover;
  border-radius: 50%;
}

.profile-icons {
  max-width: 30px; /* Adjust to your preference for larger screens */
  height: auto;
  object-fit: cover;
}

.icon-container {
  margin-left: 20%;
}

.contact-icon {
  margin-left: 2%;
}

.separator {
  display: block;
  height: 2px; /* Adjust the height of the line */
  background-color: #ccc; /* Color of the line */
  margin: 20px 0; /* Space above and below the line */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .column-layout {
    flex-direction: column; /* Stack columns vertically on smaller screens */
    align-items: center; /* Center-align items for a cleaner look */
  }

  .content {
    margin: 3% 2%;
    max-width: 95%;
  }

  .profile-image {
    width: 120px; /* Ensure the image takes up the full width of its container */
    height: 120px; /* Adjust this as needed */
    object-fit: cover;
    border-radius: 50%;
  }

  button {
    padding: 8px 15px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Slightly reduce font size */
  }
}

@media (max-width: 480px) {
  .column-layout {
    flex-direction: column;
  }

  .column {
    /* Full-width columns for very small screens */
    min-width: 100%;
    text-align: center; /* Ensures content is centered */
  }

  .row {
    font-size: 0.85em; /* Slightly reduce font size for better readability */
  }

  .profile-icons,
  .contact-icon {
    max-width: 25px; /* Adjust icons size for small screens */
  }

  .icon-container {
    /* justify-content: center;
    display: flex;
    flex-wrap: wrap; */
    margin-left: 10%;
  }

  button {
    margin-bottom: 15px; /* Add some space below the button */
  }
}
</style>
