<template>
  <div class="offer-main">
    <div class="div-left">
      <div>
        <h3>Homepage | WebDesign</h3>
        <h2>Ihre digitale Visitenkarte</h2>
        <p>WEB Präsenz einzigartig machen</p>
        <button @click="SetOfferActive('/homepage')">Details</button>
      </div>
      <div>
        <img class="offer-image" src="../assets/visit-card.jpg" alt="" />
      </div>
    </div>

    <!-- <div class="div-right">
      <div>
        <h3>Online Marketing | SEO | Analytics | Social Media</h3>
        <h2>Marketing mit Effekt</h2>
        <p>Wir erreichen Ihre Kunden</p>
        <button>Details</button>
      </div>
      <div>
        <img class="offer-image" src="../assets/visit-card.jpg" alt="" />
      </div>
    </div> -->

    <div class="div-right">
      <div>
        <h3>E-Commerce</h3>
        <h2>Verkaufen und expandieren</h2>
        <p>Wir bauen DAS Verkaufssystem</p>
        <button @click="SetOfferActive('/shop')">Details</button>
      </div>
      <div>
        <img class="offer-image" src="../assets/ecommerce.png" alt="" />
      </div>
    </div>

    <div class="div-left">
      <div>
        <h3>Outsourcing | Consulting</h3>
        <h2>Expertise extern nutzen</h2>
        <p>Verfügbar auf Stundenbasis</p>
        <button @click="SetOfferActive('/outsourcing')">Details</button>
      </div>
      <div>
        <img class="offer-image" src="../assets/consulting.jpg" alt="" />
      </div>
    </div>

    <div class="div-right">
      <div>
        <h3>Community</h3>
        <h2>Unser Netzwerk unterstützt Sie</h2>
        <p>Werdet ein Teil davon</p>
        <button @click="SetOfferActive('/community')">Details</button>
      </div>
      <div>
        <img class="offer-image" src="../assets/community.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import { MapPinIcon } from '@heroicons/vue/24/solid'

export default {
  name: "AppOffer",
  components: {},
  methods: {
    SetOfferActive(offerId) {
      this.$router.push(offerId);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.div-left {
  display: flex;
  justify-content: space-between; /* Adjust this as needed */
  padding: 10px;
  width: fit-content;
  margin-left: 5%;
  align-items: center;
}

.div-right {
  display: flex;
  justify-content: space-between; /* Adjust this as needed */
  padding: 10px;
  width: fit-content;
  margin-left: 50%;
  align-items: center;
}

.img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 350px; /* Maximum height, but allows for scaling down */
}

.div-left img,
.div-right img {
  width: auto;
  height: 350px;
  max-width: 100%;
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

.offer-image {
  margin: 5px;
  border-radius: 30px;
  max-width: 100%;
  height: auto;
}

@media (max-width: 1000px) {
  .div-left,
  .div-right {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    align-items: center; /* Center align items in a column layout */
  }

  .div-left div,
  .div-right div {
    width: 100%; /* Full width for child divs */
    text-align: center; /* Center text for smaller screens */
  }

  .offer-image {
    width: 90%; /* Slightly smaller images for medium screens */
  }
}

@media (max-width: 600px) {
  h2,
  h3 {
    font-size: 1.5em; /* Smaller font sizes for small screens */
  }

  .div-left,
  .div-right {
    padding: 5px; /* Less padding for small screens */
  }
}
</style>
