<template>
  <div class="not-found-container">
    <h1>Seite nicht gefunden</h1>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<style>
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  padding: 0 20px; /* Padding for smaller screens */
}

h1 {
  font-size: 2.5em; /* Larger font size for better visibility */
  color: #333; /* Dark color for better contrast */
}

@media (max-width: 768px) {
  h1 {
    font-size: 2em; /* Slightly smaller font size for medium screens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5em; /* Even smaller font size for small screens */
  }
}
</style>
