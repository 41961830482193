<template>
  <div class="content">
    <p>
      Sind Sie auf der Suche nach einer professionellen Webseite, die sowohl
      modern als auch leicht zu verwalten ist? Dann sind Sie bei mir genau
      richtig! Ich biete Ihnen maßgeschneiderte Web-Lösungen, die perfekt auf
      Ihre Bedürfnisse zugeschnitten sind.
    </p>
    <h2>WordPress für einfache Selbstverwaltung</h2>
    <p>
      Möchten Sie Ihre Webseite selbst verwalten, ohne tief in die
      Programmierung einzutauchen? Ich erstelle Ihre Seite mit Wordpress, einem
      benutzerfreundlichen System, das Ihnen die volle Kontrolle über Inhalte
      und Updates ermöglicht – ganz ohne Vorkenntnisse in der Programmierung.
    </p>
    <p>
      <a href="https://wordpress.com/de/" class="external-link"
        >Was ist WordPress?</a
      >
    </p>
    <h2>Modernste Technologien für Ihre Webseite.</h2>
    <p>
      Ich bin spezialisiert auf moderne Web-Entwicklung und nutze
      fortschrittliche Technologien wie VueJS, Angular, CSS3 und HTML5, um
      sicherzustellen, dass Ihre Webseite nicht nur ästhetisch ansprechend,
      sondern auch funktional und zukunftssicher ist. Zudem gewährleiste ich,
      dass Ihre Website auf allen Geräten – vom Handy bis zum Desktop – optimal
      funktioniert. Bei Bedarf entwickle ich auch Progressive Web Apps, die ein
      nahtloses und app-ähnliches Erlebnis bieten.
    </p>
    <p>
      <a
        href="https://www.ionos.at/digitalguide/websites/web-entwicklung/progressive-web-apps-welche-vorteile-bieten-sie/"
        class="external-link"
        >Was ist eine Progressive Web App?</a
      >
    </p>
    <h2>Qualität zum fairen Preis</h2>
    <p>
      Ich glaube daran, dass hochwertige Webentwicklung für jeden zugänglich
      sein sollte. Deshalb biete ich meine Dienstleistungen zu einem
      erschwinglichen Preis an – bereits
      <span class="highlight">ab 790 Euro</span>.
    </p>
    <h2>Flexible Zahlungsoptionen</h2>
    <p>
      Um Ihnen noch mehr Flexibilität zu bieten, akzeptiere ich Ratenzahlungen.
      So können Sie Ihre Webseite in bequemen Schritten finanzieren, ohne Ihre
      aktuelle Liquidität zu belasten.
    </p>
    <p>
      Lassen Sie uns gemeinsam eine Webseite kreieren, die nicht nur Ihre
      Erwartungen übertrifft, sondern auch Ihre Besucher begeistert.
      Kontaktieren Sie mich noch heute, um Ihr Projekt zu besprechen!'
    </p>

    <button @click="GoToContactPage()" class="contact-button">
      Kontaktieren Sie uns
    </button>
  </div>
</template>

<script>
export default {
  name: "HomePageDesign",
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto; /* Zentriert den Inhalt und behält den Abstand oben/unten */
  max-width: 800px; /* Begrenzt die Breite für große Bildschirme */
  padding: 0 1em; /* Adds side padding for smaller screens */
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .content {
    margin: 3% 2%;
    max-width: 95%;
  }

  .content h2 {
    font-size: 1.8em; /* Adjust font size for smaller screens */
  }

  .content p,
  .external-link {
    font-size: 1em; /* Adjust font size for paragraphs and links */
  }

  button {
    padding: 12px 24px;
    width: 100%; /* Full width buttons on small screens */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small devices */
  .content h2 {
    font-size: 1.5em;
  }

  .content p,
  .external-link {
    font-size: 0.9em; /* Slightly smaller font size for very small screens */
  }
}
</style>
