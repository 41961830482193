import { createApp } from "vue";
import App from "./App.vue";

import { createRouter, createWebHistory } from "vue-router";

import AppOffer from "./components/Offer.vue";
import CommunityPage from "./components/Pages/CommunityPage.vue";
import ContactPage from "./components/Pages/ContactPage.vue";
import DataProtection from "./components/Pages/DataProtection.vue";
import ImpressumPage from "./components/Pages/ImpressumPage.vue";
import OnlineShopPage from "./components/Pages/OnlineShopPage.vue";
import OutsourcingPage from "./components/Pages/OutsourcingPage.vue";
import PageNotFound from "./components/Pages/PageNotFound.vue";
import HomepageDesign from "./components/Pages/HomepageDesign.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/home",
      component: AppOffer,
      meta: {
        title: "VEDIL",
        metaTags: [
          {
            name: "description",
            content:
              "VEDIL ist ein Software- und Webentwicklungsunternehmen aus Graz, Österreich. Wir bieten ein breites Spektrum an IT-Dienstleistungen, darunter die Entwicklung von Websites, Onlineshops, Apps und Softwareanwendungen.",
          },
        ],
      },
    },
    {
      path: "/community",
      component: CommunityPage,
      meta: {
        title: "VEDIL Community",
        metaTags: [
          {
            name: "description",
            content:
              "Die VEDIL Community ist eine Plattform für Freelancer, die sich auf Software- und Webentwicklung spezialisiert haben. Wir bieten unseren Mitgliedern Zugang zu einem Netzwerk von erfahrenen Entwicklern, Weiterbildungsmöglichkeiten und Unterstützung bei der Projektakquise.",
          },
          {
            name: "techstack",
            content:
              "C# VueJs Angular .NET MSSQL MySQL Requirements Engineering Projektmanagement",
          },
          {
            name: "mitglied",
            content: "Werden Sie Teil unserer Freelancer Community",
          },
          {
            name: "projekt",
            content: "Unsere Freelancer Community Projekt übergeben",
          },
        ],
      },
    },
    {
      path: "/kontakt",
      component: ContactPage,
      meta: {
        title: "VEDIL Kontakt",
        metaTags: [
          {
            name: "description",
            content: "VEDIL Kontakt",
          },
        ],
      },
    },
    {
      path: "/datenschutz",
      component: DataProtection,
      meta: {
        title: "VEDIL Datenschutz",
        metaTags: [
          {
            name: "description",
            content: "VEDIL Datenschutz",
          },
        ],
      },
    },
    {
      path: "/impressum",
      component: ImpressumPage,
      meta: {
        title: "VEDIL Impressum",
        metaTags: [
          {
            name: "description",
            content: "VEDIL Impressum",
          },
        ],
      },
    },
    {
      path: "/shop",
      component: OnlineShopPage,
      meta: {
        title: "VEDIL Shop",
        metaTags: [
          {
            name: "description",
            content:
              "VEDIL erstellt WooCommerce Seiten für Unternehmen jeder Größe. Wir bieten ein umfassendes Portfolio an Leistungen, darunter die Entwicklung, Gestaltung und Umsetzung von WooCommerce Seiten.",
          },
          {
            name: "programmieren",
            content:
              "E-Commerce programmieren mit VueJS, Angular, CSS3 und HTML5",
          },
          {
            name: "guenstig",
            content: "E-Shop günstig erstellen lassen",
          },
          {
            name: "preis",
            content: "Schon ab 790 Euro",
          },
          {
            name: "location",
            content: "Graz, Österreich",
          },
        ],
      },
    },
    {
      path: "/outsourcing",
      component: OutsourcingPage,
      meta: {
        title: "VEDIL Outsourcing",
        metaTags: [
          {
            name: "description",
            content: "Outsourcing von IT Projekten",
          },
          {
            name: "techstack",
            content:
              "C# VueJs Angular .NET MSSQL MySQL Requirements Engineering Projektmanagement",
          },
          {
            name: "outfreelancer",
            content: "Outsourcing Freelancer",
          },
          {
            name: "stundenbasis",
            content: "Stundenbasis",
          },
          {
            name: "projekt",
            content: "Unsere Freelancer Community Projekt übergeben",
          },
          {
            name: "preis",
            content: "Schon ab 60 Euro",
          },
        ],
      },
    },
    {
      path: "/homepage",
      component: HomepageDesign,
      meta: {
        title: "VEDIL Web Design",
        metaTags: [
          {
            name: "description",
            content: "WordPress Seite erstellen",
          },
          {
            name: "programmieren",
            content:
              "Homepage programmieren mit VueJS, Angular, CSS3 und HTML5",
          },
          {
            name: "guenstig",
            content: "Homepage günstig erstellen lassen",
          },
          {
            name: "preis",
            content: "Schon ab 790 Euro",
          },
          {
            name: "location",
            content: "Graz, Österreich",
          },
        ],
      },
    },
    {
      path: "/:notFound(.*)",
      component: PageNotFound,
      meta: {
        title: "Seite nicht gefunden",
      },
    },
  ],
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

const app = createApp(App);

router.beforeEach((to, from, next) => {
  // Dies setzt den Dokumententitel auf den in der Route definierten Titel
  document.title = to.meta.title || "Standardtitel";

  // Entfernen Sie alle vorhandenen Meta-Tags
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Fügen Sie die Meta-Tags der aktuellen Route hinzu
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach((tag) => {
      const tagElement = document.createElement("meta");

      Object.keys(tag).forEach((key) => {
        tagElement.setAttribute(key, tag[key]);
      });

      // Setzen Sie ein Attribut, um es später leichter entfernen zu können
      tagElement.setAttribute("data-vue-router-controlled", "");

      document.head.appendChild(tagElement);
    });
  }

  next();
});

app.use(router);

router.isReady().then(() => {
  app.mount("#app");
});
