<template>
  <div class="content">
    <p>
      Suchen Sie nach einem professionellen Online-Shop, der sowohl modern als
      auch benutzerfreundlich ist? Dann sind Sie bei mir genau richtig! Ich
      biete maßgeschneiderte E-Commerce-Lösungen mit WooCommerce, die perfekt
      auf Ihre geschäftlichen Anforderungen zugeschnitten sind.
    </p>
    <h2>WooCommerce für effektiven E-Commerce</h2>
    <p>
      Wollen Sie Ihren Online-Shop selbstständig verwalten, ohne sich tief in
      die technischen Details zu vertiefen? Ich entwickle Ihren Shop mit
      WooCommerce, einer flexiblen und leistungsstarken E-Commerce-Plattform,
      die auf WordPress basiert und Ihnen volle Kontrolle über Ihre Produkte und
      Verkäufe gibt – ganz ohne Vorkenntnisse erforderlich.
    </p>
    <p>
      <a href="https://woocommerce.com/" class="external-link"
        >Was ist WooCommerce?</a
      >
    </p>
    <h2>Qualität zu fairen Preisen</h2>
    <p>
      Hochwertige E-Commerce-Entwicklung sollte für jeden zugänglich sein. Daher
      biete ich meine Dienste zu erschwinglichen Preisen an, beginnend
      <span class="highlight">ab 790 Euro</span>.
    </p>
    <h2>Flexible Zahlungsmöglichkeiten</h2>
    <p>
      Um Ihnen maximale Flexibilität zu bieten, akzeptiere ich auch
      Ratenzahlungen, damit Sie Ihren Online-Shop in bequemen Schritten
      finanzieren können, ohne Ihre Liquidität zu belasten.
    </p>
    <p>
      Lassen Sie uns gemeinsam einen Online-Shop kreieren, der Ihre Erwartungen
      übertrifft und Ihre Kunden begeistert. Kontaktieren Sie mich noch heute,
      um Ihr Projekt zu besprechen!
    </p>

    <button @click="GoToContactPage()" class="contact-button">
      Kontaktieren Sie uns
    </button>
  </div>
</template>

<script>
export default {
  name: "OnlineShopPage",
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto; /* Zentriert den Inhalt und behält den Abstand oben/unten */
  max-width: 800px; /* Begrenzt die Breite für große Bildschirme */
  padding: 0 1em; /* Adds padding for smaller screens */
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .content {
    margin: 3% 2%;
    max-width: 95%;
  }

  .content h1,
  .content h2 {
    font-size: 1.5em;
  }

  .content p,
  .external-link {
    font-size: 1em;
  }

  button {
    padding: 12px 24px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small devices */
  .content h1,
  .content h2 {
    font-size: 1.3em;
  }

  .content p,
  .external-link {
    font-size: 0.9em;
  }
}
</style>
