<template>
  <div>
    <div class="company-info">
      <p><MapPinIcon class="header-icons" />Graz, Österreich</p>
      <p><PhoneIcon class="header-icons" />+43 676 3183835</p>
      <p><EnvelopeIcon class="header-icons" />info@vedil.at</p>
    </div>
    <nav>
      <ul>
        <img class="logo" src="../assets/menu-logo.png" alt="" />
        <li><router-link class="nav-link" to="/home">Home</router-link></li>
        <li class="dropdown">
          <button
            :class="[
              'nav-link',
              { 'router-link-active': isDropdownRouteActive() },
            ]"
            class="dropbtn"
          >
            {{ leistung }}
          </button>
          <div class="dropdown-content">
            <router-link class="nav-link" to="/homepage">{{
              webDesign
            }}</router-link>
            <router-link class="nav-link" to="/shop">{{
              ecommerce
            }}</router-link>
            <router-link class="nav-link" to="/outsourcing">{{
              outsourcing
            }}</router-link>
          </div>
        </li>
        <!-- <li><button @click="setActivePage('referenzen')">Referenzen</button></li> -->
        <li>
          <router-link class="nav-link" to="/community">Community</router-link>
        </li>
        <li>
          <router-link class="nav-link" to="/kontakt">Kontakt</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { MapPinIcon } from "@heroicons/vue/24/solid";
import { PhoneIcon } from "@heroicons/vue/24/solid";
import { EnvelopeIcon } from "@heroicons/vue/24/solid";

export default {
  name: "AppHeader",
  emits: ["set-page"],
  components: {
    MapPinIcon,
    PhoneIcon,
    EnvelopeIcon,
  },
  data() {
    return {
      leistung: "Leistungen",
      webDesign: "Web Design",
      outsourcing: "Outsourcing",
      ecommerce: "E-Commerce",
    };
  },
  methods: {
    isDropdownRouteActive() {
      if (this.$route.path.includes("/homepage")) {
        this.leistung = this.webDesign;
      } else if (this.$route.path.includes("/shop")) {
        this.leistung = this.ecommerce;
      } else if (this.$route.path.includes("/outsourcing")) {
        this.leistung = this.outsourcing;
      } else {
        this.leistung = "Leistungen";
      }

      const dropdownRoutes = ["/homepage", "/shop", "/outsourcing"];
      return dropdownRoutes.includes(this.$route.path);
    },
  },
};
</script>

<style scoped>
.logo {
  height: 70px;
  width: auto;
  padding-bottom: 10px;
  margin-right: 50%;
}

.company-info {
  text-align: left;
  padding: 0;
  background-color: #2f63f1;
  align-items: center;
  padding-top: 2px;
}

.company-info > p {
  margin-right: 30px;
  display: inline;
  color: #ffffff;
  background-color: #2f63f1;
}

nav ul {
  list-style-type: none;
  background-color: #ffffff; /* Himmelblau für die Navigationsleiste */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  font-size: 22px;
}

nav ul li {
  margin-right: 35px;
}

.nav-link {
  display: inline-block;
  background: none;
  border: none;
  text-decoration: none;
  color: #2f63f1;
  padding: 5px 10px;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

.nav-link:hover,
.router-link-active {
  background-color: #2f63f1;
  color: #ffffff;
}

nav ul li button {
  background: none;
  border: none;
  text-decoration: none;
  color: #2f63f1;
  padding: 5px 10px;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

nav ul li button:hover {
  background-color: #2f63f1;
  color: #ffffff;
}

.header-icons {
  padding-top: 2px;
  height: 14px;
  width: 14px;
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.dropbtn:hover,
.router-link-active {
  background-color: #2f63f1;
  color: #ffffff;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .nav-link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content .nav-link:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 1000px) {
  .logo {
    margin-right: 50px;
    width: 60px; /* Adjust logo size */
    height: auto;
  }

  nav ul {
    justify-content: center; /* Center the navigation items */
  }

  nav ul li {
    display: block; /* Stack items vertically */
    margin-right: 0; /* Remove right margin */
  }

  @media (max-width: 600px) {
    /* .company-info > p {
      display: block;
      margin-right: 0;
      padding: 5px;
      text-align: center;
    }

    nav ul li {
      font-size: 12px;
    }

    .logo {
      height: 50px;
      width: auto;
      padding-bottom: 5px;
      margin-right: 5%;
    } */

    .company-info > p {
      display: block;
      margin-right: 0;
      padding: 5px;
      text-align: center;
    }

    .logo {
      height: 50px;
      width: auto;
      padding-bottom: 5px;
      margin-right: 5%;
    }
    nav ul li {
      font-size: 12px; /* Ensure consistent font size */
      margin: 5px 0; /* Adjusted margin for consistency */
      text-align: center; /* Center-align text for consistency */
    }

    .dropdown,
    .nav-link {
      padding: 5px 10px; /* Standardize padding */
      display: block; /* Ensure elements are block-level for consistent height */
      width: 100%; /* Full width to match other nav items */
    }

    .dropbtn {
      padding: 5px 10px; /* Ensure padding matches .nav-link for consistent height */
      line-height: normal; /* Adjust line height if necessary for consistency */
      margin: 0; /* Reset margin to avoid additional height */
    }

    /* Ensure dropdown content does not affect the dropdown button's height */
    .dropdown-content {
      display: none; /* Keep it hidden until hovered */
    }

    .dropdown:hover .dropdown-content {
      display: block; /* Only display on hover */
    }
  }
}
</style>
