<template>
  <div class="content">
    <h2>Datenschutzerklärung</h2>
    <p>
      Für uns hat der Schutz Ihrer persönlichen Daten oberste Priorität.
      Selbstverständlich halten wir die Datenschutzgesetze ein und möchten Sie
      daher umfassend über den Umgang mit Ihren Daten aufklären.
    </p>
    <p><b>Persönliche Daten</b></p>
    <p>
      Persönliche Daten, die Sie auf dieser Website elektronisch übermitteln,
      wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen
      Angaben, werden von uns nur zum jeweils angegebenen Zweck verwendet,
      sicher verwahrt und nicht an Dritte weitergegeben. Der Provider erhebt und
      speichert automatisch Informationen am Webserver wie verwendeter Browser,
      Betriebssystem, Verweisseite, IP-Adresse, Uhrzeit des Zugriffs usw. Diese
      Daten können ohne Prüfung weiterer Datenquellen keinen bestimmten Personen
      zugeordnet werden und wir werten diese Daten auch nicht weiter aus solange
      keine rechtswidrige Nutzung unserer Webseite vorliegt.
    </p>
    <p><b>Hosting</b></p>
    <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
    <p><a href="https://www.ionos.at">IONOS</a></p>
    <p>
      <a href="https://www.ionos.de/terms-gtc/datenschutzerklaerung/"
        >IONOS Datenschutzerklärung</a
      >
    </p>
    <p><b>Cookies</b></p>
    <p>
      Unsere Internetseiten verwenden sogenannte „Cookies“.Cookies sind kleine
      Dateien, die es dieser Webseite ermöglichen auf dem Computer des Besuchers
      spezifische, auf den Nutzer bezogene Informationen zu speichern, während
      unsere Website besucht wird. Cookies helfen uns dabei, die
      Nutzungshäufigkeit und die Anzahl der Nutzer unserer Internetseiten zu
      ermitteln, sowie unsere Angebote für Sie komfortabel und effizient zu
      gestalten. Wir verwenden einerseits Session-Cookies, die ausschließlich
      für die Dauer Ihrer Nutzung unserer Website zwischengespeichert werden und
      zum anderen permanente Cookies, um Informationen über Besucher
      festzuhalten, die wiederholt auf unsere Website zugreifen. Der Zweck des
      Einsatzes dieser Cookies besteht darin, eine optimale Benutzerführung
      anbieten zu können sowie Besucher wiederzuerkennen und bei wiederholter
      Nutzung eine möglichst attraktive Website und interessante Inhalte
      präsentieren zu können. Der Inhalt eines permanenten Cookies beschränkt
      sich auf eine Identifikationsnummer. Name, IP-Adresse usw. werden nicht
      gespeichert. Eine Einzelprofilbildung über Ihr Nutzungsverhalten findet
      nicht statt. Eine Nutzung unserer Angebote ist auch ohne Cookies möglich.
      Sie können in Ihrem Browser das Speichern von Cookies deaktivieren, auf
      bestimmte Webseiten beschränken oder Ihren Webbrowser (Chrome, IE,
      Firefox,…) so einstellen, dass er sie benachrichtigt, sobald ein Cookie
      gesendet wird. Sie können Cookies auch jederzeit von der Festplatte ihres
      PC löschen. Bitte beachten Sie aber, dass Sie in diesem Fall mit einer
      eingeschränkten Darstellung der Seite und mit einer eingeschränkten
      Benutzerführung rechnen müssen.
    </p>
    <p>
      <a
        href="https://www.ionos.at/digitalguide/hosting/hosting-technik/was-sind-cookies/"
        >Was sind Cookies?</a
      >
    </p>
    <p><b>Anfrage per Kontaktformular, E-Mail oder Telefon</b></p>
    <p>
      Wenn Sie uns Anfragen zukommen lassen, werden Ihre Angaben inklusive der
      von Ihnen angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
      den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
      nicht ohne Ihre Einwilligung weiter.
    </p>
    <p>
      Die von Ihnen überreichten Daten verbleiben bei uns, bis Sie uns zur
      Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
      Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
      Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
      insbesondere Aufbewahrungsfristen – bleiben unberührt.
    </p>

    <p><b>Auskunft, Löschung und Berichtigung</b></p>
    <p>
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser
      Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
      können Sie sich jederzeit an uns wenden.
    </p>

    <p><b>SSL- bzw. TLS-Verschlüsselung</b></p>
    <p>
      Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
      vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
      Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung.
      Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
      des Browsers von „http://“ auf „https://“ wechselt und an dem
      Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw.
      TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
      übermitteln, nicht von Dritten mitgelesen werden.
    </p>

    <p><b>Urheberrecht</b></p>
    <p>
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser
      Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
      können Sie sich jederzeit an uns wenden.
    </p>
    <p>
      Die auf dieser Webseite zur Verfügung gestellten Inhalte unterliegen,
      soweit dies rechtlich möglich ist, diversen Schutzrechten (z.B dem
      Urheberrecht). Eine Verwendung oder Verbreitung von bereitgestelltem
      Material, bedarf der schriftlichen Zustimmung des Webseitenbetreibers.
    </p>
    <br />
    <p>
      Die auf dieser Webseite verwendeten Bilder und Icons stammen aus
      verschiedenen Quellen. Wir möchten die Urheber und deren kreative Arbeit
      würdigen und danken den folgenden Quellen für die Bereitstellung ihrer
      Inhalte:
    </p>
    <div>
      <div><a href="https://heroicons.com/">Heroicons</a></div>
      <div>
        <a href="https://pixabay.com/">Pixabay</a>
      </div>
      <div>
        <a href="https://unsplash.com/">Unsplash</a>
      </div>
    </div>
    <p></p>
    <p>
      Alle Bilder und Icons, die von diesen Quellen verwendet werden, sind
      entweder lizenzfrei, gemeinfrei oder werden unter Einhaltung der
      jeweiligen Lizenzbedingungen verwendet. Wir respektieren das Urheberrecht
      und bemühen uns, alle Urheberrechte zu beachten. Sollten Sie auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis, damit wir den betroffenen Inhalt umgehend
      entfernen können.
    </p>

    <p></p>
    <p><b>Haftungsausschluss</b></p>
    <p>
      Der Betreiber dieser Webseite hat alle Inhalte sowie ausgehende Links mit
      größtmöglichem Sorgfaltsmaßstab kontrolliert und ausgewählt. Trotzdem wird
      keine Haftung für den Inhalt externer Webseiten übernommen. Für diesen ist
      ausschließlich deren jeweiliger Betreiber verantwortlich. Für den Fall,
      dass Sie auf ausgehende Links aufmerksam werden, welche auf eine Seite mit
      rechtswidriger Tätigkeit oder rechtswidrigem Inhalt verweisen, ersuchen
      wir um entsprechenden Hinweis an die obig angeführte Email-Adresse, um
      betroffene Inhalt umgehend nach § 17 (2) ECG zu entfernen. <br />
      Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter
      Sorgfalt beachtet. Sollten Sie auf eine Urheberrechtsverletzung aufmerksam
      werden, ersuchen wir auch um einen entsprechenden Hinweis. Bei
      Bekanntwerden derartiger Rechtsverletzungen wird der Webseitenbetreiber
      den betroffenen Inhalt umgehend entfernen.
    </p>

    <p>
      Verbraucher haben die Möglichkeit, Beschwerden an die
      Online-Streitbeilegungsplattform der EU zu richten:
      http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben
      angegebene E-Mail-Adresse richten.
    </p>
    <p><b>Ihre Rechte</b></p>
    <p>
      Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
      Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch
      zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
      Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst
      in einer Weise verletzt worden sind, können Sie sich an die
      Aufsichtsbehörde wenden.
    </p>
    <p>In Österreich ist dies die Datenschutzbehörde.</p>
    <p>Österreichische Datenschutzbehörde</p>
    <p>Hohenstaufengasse 3, 1010 Wien</p>
    <p>E-Mail: dsb@dsb.gv.at</p>
  </div>
</template>

<script>
export default {
  name: "DataProtection",
  methods: {
    GoToContactPage() {
      this.$router.push("/kontakt");
    },
  },
};
</script>

<style>
.content {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 3% auto; /* Zentriert den Inhalt und behält den Abstand oben/unten */
  max-width: 800px; /* Begrenzt die Breite für große Bildschirme */
  padding: 0 1em; /* Adds side padding for smaller screens */
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.content h2 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em; /* Größer als der Standardtext */
}

.external-link {
  color: #0073aa; /* WordPress Blau */
  font-weight: bold;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.external-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hovern hinzu */
}

button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding, Left and right padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to a hand icon when hovered over */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover and click effects */
}

button:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  transform: scale(1.05); /* Slightly increase the size when hovered */
}

button:active {
  background-color: #004085; /* Even darker shade when clicked */
  transform: scale(0.95); /* Slightly decrease the size when clicked */
}

/* Responsive Styling für kleinere Bildschirme */
@media (max-width: 768px) {
  .content {
    margin: 3% 2%; /* Adjusts margins for medium screens */
    max-width: 95%; /* Allows content to take more width on smaller screens */
  }

  .content h2 {
    font-size: 1.8em; /* Adjusts heading font sizes for smaller screens */
  }

  .content p {
    font-size: 1em; /* Adjusts paragraph font sizes for readability */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small devices like mobile phones */
  .content h2 {
    font-size: 1.5em; /* Slightly smaller headings for small screens */
  }

  .content p {
    font-size: 0.9em; /* Slightly smaller text for better readability */
  }
}
</style>
